import moment from "moment";
import React from "react";
interface propsType {
  taskDetail?: any;
}

const LeadTaskDetailTable: React.FC<propsType> = ({ taskDetail }) => {
  return (
    <>
      <span className=" text-[14px] font-medium  py-2 ">Task Details</span>
      <div className="w-full flex border h-[400px] p-4 scrollable    overflow-auto border-[#A0A0A0] rounded-[10px] ">
        <table className="w-full ">
          <tbody className="">
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Lead:</td>
              <td className="text-[14px]">
                {taskDetail?.ownerDetails[0]?.name
                  ? taskDetail?.ownerDetails[0]?.name.toUpperCase()
                  : "NA"}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Assignee:</td>
              <td className="text-[14px]">
                {taskDetail?.assignUsersData?.map(
                  (item: any) => item?.name.toUpperCase() + " "
                )}
              </td>
            </tr>
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Owner:</td>
              <td className="text-[14px]">
                {taskDetail?.ownerDetails[0]?.name.toUpperCase()}
              </td>
            </tr> */}
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Priority:</td>
              <td className="text-[14px]">
                {taskDetail?.priority.toUpperCase()}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Deadline:</td>
              <td className="text-[14px]">
                {moment(taskDetail?.deadline).format("Do MMMM  YYYY")}
              </td>
            </tr>
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Task Type:</td>
              <td className="text-[14px]">
                {taskDetail?.taskType.toUpperCase()}
              </td>
            </tr> */}
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Status:</td>
              <td className="text-[14px]">
                {taskDetail?.status.toUpperCase()}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Creation Date:</td>
              <td className="text-[14px]">
                {moment(taskDetail?.leadData[0]?.createdAt).format(
                  "Do MMMM  YYYY"
                )}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Date Contacted:</td>
              <td className="text-[14px]">
                {moment(taskDetail?.leadData[0]?.updatedAt).format(
                  "Do MMMM  YYYY"
                )}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Lead Email:</td>
              <td className="text-[14px]">
                {taskDetail?.leadData[0]?.email
                  ? taskDetail?.leadData[0]?.email.toUpperCase()
                  : "NA"}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Lead Phone:</td>
              <td className="text-[14px]">
                {taskDetail?.lead_Phone ? taskDetail?.lead_Phone : "NA"}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-3 text-[#7A7A7A]">Text:</td>
              <td className="text-[14px]">
                {taskDetail?.text_for_Lead
                  ? taskDetail?.text_for_Lead.toUpperCase()
                  : "NA"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LeadTaskDetailTable;
