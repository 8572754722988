import { useLocation, useNavigate } from "react-router-dom";
import { TbBellFilled } from "react-icons/tb";
import { BsPersonCircle } from "react-icons/bs";
import HamIconSVG from "../../assets/image/svg/HamIconSVG";
import SearchBar from "./SearchBar";
import MyProfile from "./MyProfile";
import Notification from "./Notification";


interface propsType {
  setNotification?: any;
  notification?: any;
  profile?: boolean;
  setProfile?: any;
  setPass?: (a: any) => void;
  setProf?: (a: any) => void;
  setSearch: (a: string) => void;
  windowWidth: any;
  setShowScreen: any;
}

const Header: React.FC<propsType> = ({
  setNotification,
  notification,
  profile,
  setProfile,
  setPass,
  setProf,
  setSearch,
  windowWidth,

  setShowScreen,
}) => {
  const id = useLocation();
  const nav = useNavigate();

  const ArrayData: Array<any> = [
    {
      name: "Task",
      url: "/dashboard",
    },
    {
      name: "MaintenanceSureStay",
      url: "/labCase",
    },
    {
      name: "Maintenance",
      url: "/maintenanceNotSureStay",
    },
    {
      name: "Users",
      url: "/user",
    },
    {
      name: "Document",
      url: "/document",
    },

  ];

  return (
    <>
      {id.pathname !== "/registerUser" &&
        id.pathname !== "/AddTask" &&
        id.pathname !== "/AddLeadTask" &&
        id.pathname !== "/edit" && (
          <div
            className="w-[100%]   py-4 z-50  flex items-center  top-0 pl-7 pr-6 justify-between"

          >
            {/* {id.pathname === "*" || id.pathname === "/" || id.pathname === "/dashboard" || id.pathname === "/user" || id.pathname === "/leadTask" ? (
              <div className="min-771:hidden">
                <span onClick={() => setShowScreen && setShowScreen("sidebar")}>
                  <HamIconSVG />
                </span>
              </div>
            ) : null} */}
            <div className="min-771:hidden">
              <span onClick={() => setShowScreen && setShowScreen("sidebar")}>
                <HamIconSVG />
              </span>
            </div>
            {windowWidth > 750 && <SearchBar setSearch={setSearch} />}

            <div className="h-full relative flex flex-row items-center gap-6">
              <div className="w-full flex md:hidden gap-8">
                {ArrayData.map((item, index) => (
                  <span
                    key={index}
                    onClick={() => nav(item.url)}
                    className="text-[#3D5765] font-medium cursor-pointer"
                  >
                    {item?.name}
                    {id?.pathname === item?.url && (
                      <div className="border border-[#3D5765]"></div>
                    )}
                  </span>
                ))}
              </div>
              <div className="h-full cursor-pointer">
                <TbBellFilled
                  color="#3D5765"
                  size={30}
                  onClick={() => {
                    setNotification(!notification);
                    setProfile(false);
                  }}
                />
              </div>
              <div className="h-[100%]">
                {notification && (
                  <Notification
                    setNotification={setNotification}
                    notification={notification}
                  />
                )}
                {profile && (
                  <MyProfile

                    profile={profile}
                    setPass={setPass}
                    setProfile={setProfile}
                    setProf={setProf}
                  />
                )}
                <BsPersonCircle
                  color="#3D5765"
                  size={30}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setProfile(!profile);
                    setNotification(false);
                  }}
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Header;
