import { SVGProps } from "react"

const CalenderSVG = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={11}
    fill="none"
    {...props}
  >
    <g fill="#D65252" clipPath="url(#a)">
      <path d="M8.958 1.988h-1.11v.834a.611.611 0 0 1-1.223 0v-.834H3.39v.834a.611.611 0 0 1-1.222 0v-.834H1.056a.494.494 0 0 0-.5.503v6.217a.495.495 0 0 0 .486.502h7.916a.494.494 0 0 0 .486-.502V2.49a.494.494 0 0 0-.486-.503Zm-6.18 5.556h-.556v-.556h.556v.556Zm0-1.39h-.556V5.6h.556v.556Zm0-1.388h-.556V4.21h.556v.556Zm1.666 2.778H3.89v-.556h.555v.556Zm0-1.39H3.89V5.6h.555v.556Zm0-1.388H3.89V4.21h.555v.556Zm1.667 2.778h-.555v-.556h.555v.556Zm0-1.39h-.555V5.6h.555v.556Zm0-1.388h-.555V4.21h.555v.556Zm1.667 2.778h-.556v-.556h.556v.556Zm0-1.39h-.556V5.6h.556v.556Zm0-1.388h-.556V4.21h.556v.556Z" />
      <path d="M2.778 3.1a.278.278 0 0 0 .278-.278V1.155a.278.278 0 0 0-.556 0v1.667a.278.278 0 0 0 .278.277ZM7.222 3.1a.278.278 0 0 0 .278-.278V1.155a.278.278 0 1 0-.556 0v1.667a.278.278 0 0 0 .278.277Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .322h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default CalenderSVG