import React, { useEffect } from 'react'
import { Droppable } from 'react-beautiful-dnd';
import ArrowSVG from '../../assets/image/svg/ArrowSVG';
import CartTask from './CartTask';
import { IoMdArrowBack } from 'react-icons/io';

interface PropsType{
  inprogress:any;
  setTaskDetail?:any;
  setViewTask?:any;
  setView?:any
  type?:any
  setShowTask?:any
}

const InprogressTask:React.FC<PropsType> = ({inprogress,setTaskDetail,setViewTask,setShowTask,type}) => {

  console.log({inprogress})
  return (
    <>
    <div className="mt-4 lg:hidden">
    <IoMdArrowBack size={25} onClick={()=>setShowTask("")} />
  </div>
   <Droppable droppableId="InProgressList">
            {(provided) => (
              <div
                className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px] md:w-full  md:mt-[20px] "
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className="h-full flex  justify-center flex-wrap overflow-auto  ">
                  <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                    <div className="w-full flex px-2 ">
                      <span
                        className="text-[16px] font-semibold text-center px-5 py-1 bg-[#EADDD5] rounded-[7px] text-[#EE7E2E] "
                        style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                      >
                    {type === "labCase" ? "Recieved" : "IN PROGRESS"}     ({inprogress?.length})
                      </span>
                    </div>
                    <div className="flex flex-col  py-1 px-2">
                      <span className="font-medium text-[14px]">
                        Task Started
                      </span>
                      <span className="text-[#505050] text-[13px]">
                      The team started working on the tasks.
                      </span>
                    </div>
                    <div className="h-full flex flex-col gap-2 w-full overflow-auto  ">
                      {inprogress && inprogress?.map((item: any, index: number) => {
                        return (
                          <CartTask
                            setViewTask={setViewTask}
                            setTaskDetail={setTaskDetail}
                            icon={<ArrowSVG />}
                            item={item}
                            index={index}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          </>
  )
}

export default InprogressTask