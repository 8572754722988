import React, { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import ArrowSVG from "../../assets/image/svg/ArrowSVG";
import CartTask from "./CartTask";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

interface PropsType {
  todo: any;
  setTaskDetail: any;
  setViewTask: any;
  setView?: any;
  view?: any;
  // taskDetail: any;
  setShowTask?: any;
  type?: any

}

const TodoTask: React.FC<PropsType> = ({
  todo,
  setShowTask,
  setTaskDetail,
  setViewTask,
  type,
  // taskDetail

}) => {

  return (
    <>
      <div className="mt-4 lg:hidden">
        <IoMdArrowBack size={25} onClick={() => setShowTask("")} />
      </div>
      <Droppable droppableId="TodosList">
        {(provided) => (
          <div
            className="w-1/3 md:w-full h-full bg-[#E2E8F0] p-2  md:mt-[20px] rounded-[15px]"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className=" h-full flex justify-center flex-wrap overflow-auto">
              <div className="w-full h-full flex flex-col gap-4 px-2 py-3">
                <div className="w-full flex px-2 ">
                  <span
                    className="text-[16px]  font-semibold text-center px-5 py-1 bg-[#CDEAFF] rounded-[7px] text-[#0496FF] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    {type === "labCase" ? "Send" : "TO DO"}  ({todo?.length})
                  </span>
                </div>
                <div className="flex flex-col py-1 px-2 items-start">
                  <span className="font-medium text-[14px]">Task Assigned</span>
                  <span className="text-[#505050] text-[13px]">
                    The tasks are assigned to team members.
                  </span>
                </div>
                <div className="h-full flex flex-col gap-2 w-full overflow-auto">
                  {todo?.map((item: any, index: number) => {

                    return (
                      <CartTask
                        setViewTask={setViewTask}
                        setTaskDetail={setTaskDetail}
                        icon={<ArrowSVG />}
                        item={item}
                        // taskDetail={taskDetail}
                        index={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default TodoTask;
