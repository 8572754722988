import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AllTaskScreen from "../Screens/AllTaskScreen";
import RegisterUserComponent from "../Components/User Component/RegisterUserComponent";
import AllUserScreen from "../Screens/AllUserScreen";
import AllLeadTask from "../Screens/AllLeadTask";
import AddLeadScreen from "../Screens/AddLeadScreen";
import AllEditScreen from "../Screens/AllEditScreen";
import AddTaskScreen from "../Screens/AddTaskScreen";
import SideBar from "../Components/sidebar Component/SideBar";
import AddMemberSVG from "../assets/image/svg/AddMemberSVG";
import AddRegisterSVG from "../assets/image/svg/AddRegisterSVG";
import AddTaskSVG from "../assets/image/svg/AddTaskSVG";

import toast from "react-hot-toast";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks";
import { getAllUser } from "../Slices/UserSlice";
import { getAllLabCases, getAllTasks, updateTask } from "../Slices/TaskSlice";
import EditUserScreen from "../Screens/EditUserScreen";
import Header from "../Components/Header Component/Header";
import UpdatePassword from "../Components/Update Component/UpdatePassword";
import UpdateProfile from "../Components/Update Component/UpdateProfile";
import { FaUserDoctor } from "react-icons/fa6";
import AllLabCaseScreen from "../Screens/AlLlabCaseScreen";
import AddLabCasesScreen from "../Screens/AddLabCasesScreen";
import DocumentScreen from "../Screens/DocumentScreen";
import LabCaseSVG from "../assets/image/svg/LabCaseSVG";
import { IoMdDocument } from "react-icons/io";

const Auth = () => {
  const sideBarData = [
    {
      icon: (
        <AddTaskSVG
          color={
            window.location.pathname.includes("/dashboard") &&
              window.location.pathname.includes("*") &&
              window.location.pathname.includes("/")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/dashboard",
    },

    {
      icon: (
        <FaUserDoctor
          color={
            window.location.pathname.includes("/labCase")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      url: "/labCase",
    },

    // {
    //   icon: (
    //     <AddRegisterSVG
    //       color={
    //         window.location.pathname.includes("/leadTask")
    //           ? "#02647F"
    //           : "#000000"
    //       }
    //     />
    //   ),
    //   url: "/leadTask",
    // },

    {
      icon: (
        <AddMemberSVG
          color={
            window.location.pathname.includes("/user") ? "#02647F" : "#000000"
          }
        />
      ),
      url: "/user",
    },
    {
      icon: (
        <IoMdDocument size={25}
          color={
            window.location.pathname.includes("/document") ? "#B8B9C8" : "#B8B9C8"
          }
        />
      ),
      url: "/document",
    },
    {
      icon: (
        <FaUserDoctor
          color={
            window.location.pathname.includes("/maintenanceNotSureStay")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      url: "/maintenanceNotSureStay",
    },
  ];
  const [notification, setNotification] = useState(false);
  const [profile, setProfile] = useState(false);
  const [pass, setPass] = useState(false);
  const [prof, setProf] = useState(false);
  const [todo, setTodo] = useState<any>([]);
  const [inprogress, setInProgress] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);
  const [leadTodo, setLeadTodo] = useState<any>([]);
  const [LeadInprogress, setLeadInProgress] = useState<any>([]);
  const [Leadcompleted, setLeadCompleted] = useState<any>([]);
  const [send, setSend] = useState<any>([]);
  const [received, setReceived] = useState<any>([]);
  const [delivered, setDelivered] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userDetails, setUserDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [refetch, setRefetch] = useState(1);
  const [taskData, setTaskData] = useState<any>([]);
  const [showScreen, setShowScreen] = useState<string>("");
  const [labCaseData, setLabCaseData] = useState<any>({
    received: [],
    send: [],
    delivered: []
  })

  const [loader, setLoader] = useState(false);
  const { pathname } = useLocation();
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const handleUpdateFunc = (value: any, status: string) => { // Api for Update function
    let payload;
    setLoader(true);
    if (value?.lead === null) {
      payload = {
        task_id: value?._id,
        task_details: {
          title: value?.title,
          comments: value?.commentsData ? value?.commentsData : [""],
          description: value?.description,
          owner: value?._id,
          priority: value?.priority.toLowerCase(),
          status: status,
          deadline: value?.deadline,
          assign_to: value?.assign_to ? value?.assign_to : [""],
          taskType: value?.taskType.toLowerCase(),
        },
        attached_files: value?.DocumentsData,
      };
    } else {
      payload = {
        task_id: value?._id,
        task_details: {
          title: value?.title,
          description: value?.description,
          owner: value?._id,
          comments: value?.commentsData ? value?.commentsData : [""],
          priority: value?.priority.toLowerCase(),
          status: status,
          deadline: value?.deadline,
          assign_to: value?.assign_to ? value?.assign_to : [""],
          taskType: value?.taskType.toLowerCase(),
          lead: AdminUser?._id ? AdminUser?._id : "",
          lead_Phone: value?.LeadPhone ? value?.LeadPhone : "",
          text_for_Lead: value?.text ? value?.text : "",
        },
        attached_files: value?.DocumentsData,
      };
    }

    dispatch(updateTask(payload))
      .unwrap()
      .then((res) => {

        if (res?.success === true) {
          setRefetch(refetch + 1);
          setLoader(false);

          toast.success(res?.msg, {
            position: "top-center",
          });

        }

      })
      .catch((error) => {
        toast.error(error?.msg);
        setLoader(false);
      });
  };

  const onDragEnd = (result: DropResult) => {
    //  on drag library 
    const { destination, source } = result;
    console.log(destination, source, "source & destinations data")
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let add;
    let temp_todo = todo;
    let temp_Inprogress = inprogress;
    let temp_completed = completed;

    if (source.droppableId === "TodosList") {
      add = temp_todo[source.index];
      temp_todo.splice(source.index, 1);
    } else if (source.droppableId === "InProgressList") {
      add = temp_Inprogress[source.index];
      temp_Inprogress.splice(source.index, 1);
    } else {
      add = temp_completed[source.index];
      temp_completed.splice(source.index, 1);
    }

    // Destination Logic
    console.log(add, "add data dragable")
    if (destination.droppableId === "TodosList") {
      temp_todo.splice(destination.index, 0, add);
      // handleUpdateFunc(add, "todo");
      nav("/edit",
        { state: [{ ...add, status: "todo" }] }
      )

    } else if (destination.droppableId === "InProgressList") {
      temp_Inprogress.splice(destination.index, 0, add);
      // handleUpdateFunc(add, "inprogress");
      nav("/edit",
        { state: [{ ...add, status: "inprogress" }] }
      )

    } else {
      temp_completed.splice(destination.index, 0, add);
      // handleUpdateFunc(add, "completed");
      nav("/edit",
        { state: [{ ...add, status: "completed" }] }
      )

    }

    // setTodo([...temp_todo]);
    // // settodoLength(todo.length);
    // setInProgress([...temp_Inprogress]);
    // // setInprogLength(inprogress.length);
    // setCompleted([...temp_completed]);
    // // setCompLength(completed.length);

  };

  const getUser = () => { // this api for the user
    const payload = {
      search: search.length ? search : "",
    };
    dispatch(getAllUser(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setUserDetails(res?.response?.user);
          // toast("Successful ✅", {
          //   position: "top-right",
          // });
        }
      })
      .catch((error) => {
        toast.error(error?.msg);
      });
  };

  const HandleGetTaskFunc = () => { // this api for all task
    const payload = {
      status: "all",
      search: search.length && search,
    };
    dispatch(getAllTasks(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setTaskData(res?.taskData);
        }
      })
      .catch((error) => {
        console.log("error", error);
        // toast.error(error?.msg);
      });
  };

  const handleGetAllLabCase = () => { // this api for all lab case
    const payload = {
      search: "",
    };
    dispatch(getAllLabCases(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {

          console.log(res.labCaseData, "==================res is here");
          res?.labCaseData?.forEach((item: any) => {
            console.log("item", item);

            if (item.status === "recieved") {
              setLabCaseData((prevState: any) => ({
                ...prevState,
                received: [...prevState.received, item] // Spread previous array and add new item
              }));
            }
            else if (item.status === "delivered") {
              setLabCaseData((prevState: any) => ({
                ...prevState,
                delivered: [...prevState.delivered, item] // Spread previous array and add new item
              }));
            }
            else if (item.status === "send") {
              setLabCaseData((prevState: any) => ({
                ...prevState,
                send: [...prevState.send, item] // Spread previous array and add new item
              }));
            }
          });
        }
      })
      .catch((error) => {
        console.log("===================error", error);
      });
  };

  console.log("=========labCaseData", labCaseData)

  useEffect(() => { // here we call function
    HandleGetTaskFunc();
    handleGetAllLabCase();
  }, [search, refetch]);

  useEffect(() => { // in this seperate todo inprogress complete in different state because all data coming from same api.  
    const tempTodo: any = [];
    const tempInProgress: any = [];
    const tempCompleted: any = [];
    const tempLeadTodo: any = [];
    const tempLeadInProgress: any = [];
    const tempLeadCompleted: any = [];

    taskData &&
      taskData.map((item: any) => {
        if (item.lead === null && item.status === "todo") {
          tempTodo.push(item);
        } else if (item.lead === null && item.status === "inprogress") {
          tempInProgress.push(item);
        } else if (item.lead === null && item.status === "completed") {
          tempCompleted.push(item);
        } else if (item.lead !== null && item.status === "todo") {
          tempLeadTodo.push(item);
        } else if (item.lead !== null && item.status === "inprogress") {
          tempLeadInProgress.push(item);
        } else if (item.lead !== null && item.status === "completed") {
          tempLeadCompleted.push(item);
        }
      });
    setCompleted([...tempCompleted]);
    setInProgress([...tempInProgress]);
    setTodo([...tempTodo]);
    setLeadCompleted([...tempLeadCompleted]);
    setLeadInProgress([...tempLeadInProgress]);
    setLeadTodo([...tempLeadTodo]);
  }, [refetch, taskData, search]);

  useEffect(() => {
    getUser();
    const handleResize = () => { // this is function for the window size
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [search, refetch]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <>
        <div className="h-[100vh] w-[100vw] flex bg-[#EDF1F6] ">
          {/* <BrowserRouter> */}

          <SideBar data={sideBarData} />

          <div className="h-[100vh] w-[100vw] overflow-auto md:overflow-hidden ">
            {window.location.pathname !== "/registerUser" &&
              window.location.pathname !== "/AddTask" &&
              window.location.pathname !== "/AddLeadTask" &&
              window.location.pathname !== "/edit" &&
              window.location.pathname !== "/editUser" &&
              window.location.pathname !== "/AddLabCase" &&
              (
                <Header // header 
                  setNotification={setNotification}
                  notification={notification}
                  setProfile={setProfile}
                  profile={profile}
                  setPass={setPass}
                  setProf={setProf}
                  setSearch={setSearch}
                  windowWidth={windowWidth}
                  setShowScreen={setShowScreen}
                />
              )}
            <div
              className="w-[100%] overflow-auto"
              style={
                pathname !== "/registerUser" &&
                  pathname !== "/AddTask" &&
                  pathname !== "/AddLeadTask" &&
                  pathname !== "/edit" &&
                  pathname !== "/editUser" &&
                  pathname !== "/AddLabCase"
                  ? { height: "calc(100% - 72px)" } // this condition for height because this component doesn't need header space. 
                  : { height: "100%" }
              }
            >
              <Routes>
                <Route
                  path="*"
                  element={
                    <AllTaskScreen
                      todo={todo}
                      inprogress={inprogress}
                      completed={completed}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      loader={loader}
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    <AllTaskScreen
                      todo={todo}
                      inprogress={inprogress}
                      completed={completed}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      loader={loader}
                    />
                  }
                />
                <Route
                  path="/maintenanceNotSureStay"
                  element={
                    <AllTaskScreen
                      todo={todo}
                      inprogress={inprogress}
                      completed={completed}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      loader={loader}
                    />
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <AllTaskScreen
                      todo={todo}
                      inprogress={inprogress}
                      completed={completed}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      loader={loader}
                    />
                  }
                />
                <Route
                  path="/labCase"
                  element={
                    <AllLabCaseScreen
                      todo={labCaseData.send} // this data is coming from all task please it from lab case data todo is replaced from send state   
                      inprogress={labCaseData.received} // this data is coming from all task please it from lab case data inprogress is replaced from received
                      completed={labCaseData.delivered} // this data is coming from all task please it from lab case data completed is replaced from delivered
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      loader={loader}
                    />
                  }
                />

                <Route
                  path="/leadTask"
                  element={
                    <AllLeadTask
                      todo={leadTodo}
                      inprogress={LeadInprogress}
                      completed={Leadcompleted}
                      taskData={taskData}
                      setTaskData={setTaskData}
                      refetch={refetch}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                    />
                  }
                />
                <Route
                  path="/user"
                  element={
                    <AllUserScreen
                      userDetails={userDetails}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                    />
                  }
                />

                <Route path="/document" element={<DocumentScreen />} />
                <Route
                  path="/edit"
                  element={
                    <AllEditScreen refetch={refetch} handleUpdateFunc={handleUpdateFunc} setRefetch={setRefetch} />
                  }
                />
                <Route
                  path="/AddLeadTask"
                  element={
                    <AddLeadScreen refetch={refetch} setRefetch={setRefetch} />
                  }
                />
                <Route
                  path="/AddTask"
                  element={
                    <AddTaskScreen refetch={refetch} setRefetch={setRefetch} />
                  }
                />
                <Route path="/AddLabCase" element={<AddLabCasesScreen refetch={refetch} setRefetch={setRefetch} />} />
                <Route
                  path="/registerUser"
                  element={
                    <RegisterUserComponent
                      setRefetch={setRefetch}
                      refetch={refetch}
                    />
                  }
                />
                <Route
                  path="/editUser"
                  element={
                    <EditUserScreen setRefetch={setRefetch} refetch={refetch} />
                  }
                />
              </Routes>
            </div>
          </div>
          {/* </BrowserRouter> */}
          {pass && <UpdatePassword setPassword={setPass} />}
          {prof && <UpdateProfile setProf={setProf} />}
        </div>
      </>
    </DragDropContext>
  );
};

export default Auth;
