import React, { useEffect, useRef, useState } from "react";
import { BiSolidFilePdf } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import { BiSolidFileJpg } from "react-icons/bi";
import { FaFileWord } from "react-icons/fa";

interface FieldPropsType {
  value: valueType[];
}
interface valueType {
  createdAt: string;
  document_ext: string;
  document_field: string;
  document_link: string;
  document_name: string;
  document_type: string;
  task_id: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

const UploadFile: React.FC<FieldPropsType> = ({ value }) => {

  

  return (
    <>
      <div className="pb-2">
        <span className="font-medium">Attach Files</span>
      </div>
      {!value.length ? (
        <span className="text-[16px] font-semibold italic text-[#A0A0A0]">
          No Attach Files
        </span>
      ) : (
        <>
          {value?.map((item: any, index: any) => {
            
            return (
              <div
                className="flex flex-row items-center gap-1 p-[2px] bg-[#F6F6F6] rounded-md mb-2 pr-8 relative"
                style={{ boxShadow: "0px 2px 8px 0px #00000040" }}
              >
                {item?.document_ext?.includes("image") ? (
                  <BiSolidFileJpg color="#007cb8" size={40} />
                ) : item?.document_ext?.includes("pdf") ? (
                  <BiSolidFilePdf color="#DF0000" size={40} />
                ) : (
                  <FaFileWord color="#007cb8" size={40} />
                )}
                <div className="w-full">
                  <h3 className="font-medium font text-[12px] pb-1 leading-tight max-w-[90%] break-all">
                    {item?.document_name}
                  </h3>

                  <p className="flex gap-4">
                    <a
                      href={item?.document_link}
                      target="_blank"
                      className="text-[#0096C6] text-[12px] flex flex-row items-center gap-1"
                    >
                      Download <MdOutlineFileDownload />
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default UploadFile;
