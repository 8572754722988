import { useState } from "react";
import SearchBar from "../Header Component/SearchBar";
import RespoSideBar from "./RespoSidebar";
import RespoSelectTask from "./RespoSelectTask";
import TodoTask from "../TaskComponents/TodoTask";
import InprogressTask from "../TaskComponents/InprogressTask";
import CompletedTask from "../TaskComponents/CompletedTask";
import ViewSingleTask from "../TaskComponents/ViewSingleTask";
import { IoMdArrowBack } from "react-icons/io";

interface PropsType {
  todo: any;
  inprogress: any;
  completed: any;
  showScreen: any;
  setShowScreen: any;
  button: string;
  setTaskDetail: any;
  setViewTask: any;
  taskDetail: any;
  viewTask: any;
  setRefetch: any;
  refetch: any;
  setView?: any;
  name: any;
}

const RespoAllTask: React.FC<PropsType> = ({
  todo,
  inprogress,
  completed,
  showScreen,
  setShowScreen,
  button,
  setTaskDetail,
  setViewTask,
  taskDetail,
  viewTask,
  refetch,
  setRefetch,
  name
}) => {
  const [showTask, setShowTask] = useState<string>("");

  return (
    <>
      <div className="w-[100%] h-[100%] px-[20px] py-[10px] ">
        <SearchBar />
        <div
          className="w-full flex  flex-col "
          style={{ height: "calc(100% - 110px)" }}
        >
          {showTask ? (
            showTask === "todo" ? (
              <TodoTask
                todo={todo}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setShowTask={setShowTask}
              />
            ) : showTask === "inprogress" ? (
              <InprogressTask
                inprogress={inprogress}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setShowTask={setShowTask}
              />
            ) : (
              <CompletedTask
                completed={completed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setShowTask={setShowTask}
              />
            )
          ) : (
            <RespoSelectTask button={button} setShowTask={setShowTask} name={name} />
          )}
        </div>
      </div>

      {viewTask && (
        <ViewSingleTask
          setViewTask={setViewTask}
          taskDetail={taskDetail}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      )}
      {showScreen.length > 0 ? (
        <RespoSideBar
          setShowSlider={setShowScreen}
          showSlider={showScreen}
          type={"sidebar"}
          direction={"left"}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RespoAllTask;
