import React from 'react'
import NewHeader from '../Components/Header Component/NewHeader'
import Documents from '../Components/allTaskDocument/Documents'

const DocumentScreen = () => {
  return (
   <div className="h-full w-full flex">
      <div className="w-full h-full flex flex-col gap-2 py-4 px-10 md:px-4">
        <NewHeader heading="Documents" />
        <Documents/>
      </div>
    </div>
  )
}

export default DocumentScreen